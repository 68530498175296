import * as Sentry from "@sentry/gatsby";

Sentry.init({
    dsn: "https://40cec41cccc3dcdc46990b2dbe547584@o4507095512711168.ingest.de.sentry.io/4507096770281552",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [
                /^https:\/\/ortorex/,
                /^https:\/\/admin\./,
                /^https:\/\/stripe\./,
                /^https:\/\/paypal\./,
            ],
        }),
        // Sentry.captureConsoleIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: process.env.GATSBY_SENTRY === 'true',
    environment: process.env.GATSBY_SITE_URL 
    ? process.env.GATSBY_SITE_URL.replace(/^https?:\/\//, '').replace(/\/$/, '') 
    : 'ortorex_unknown',
});