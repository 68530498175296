import { updateCartStore, $updateCartId, $productAddToCart } from "./store";


export const WP_API = `${process.env.GATSBY_HTTPS ? 'https' : 'http'}://${process.env.GATSBY_API}/wp-json/wp/v2`;
export const PRODUCT_API = `${process.env.GATSBY_HTTPS ? 'https' : 'http'}://${process.env.GATSBY_API}/wp-json/gatsby/v1`;

// TODO: replace for production
// export const SITE_URL = "http://localhost:8000";
export const SITE_URL = process.env.GATSBY_SITE_URL;

export const PAYPAL_OPTIONS = {
    // locale: process.env.GATSBY_LANG,
    clientId: process.env.GATSBY_PAYPAL,
    currency: "USD",
    "disable-funding": "card,p24",
} as const;

export const STRIPE_OPTIONS = {
    mode: "payment",
    amount: 100,
    currency: "usd",
    // locale: process.env.GATSBY_LANG,
    paymentMethodCreation: "manual",
    appearance: {
        theme: "stripe",
        rules: {
            ".Input::placeholder": {
                color: "var(--colorText)",
            },
            // ".Label": {
            //   opacity: "0",
            // },
        },
        variables: {
            colorBackground: "#ffffff",
            colorText: "#515151",
            fontFamily: "'Verano Sans', sans-serif",
            borderRadius: "0",
            // spacingUnit: "4px",
            // spacingGridColumn: "40px",
            colorIconCardCvc: "#00000000",
        },
    },
} as const;

const getSessionCookieAsURI = () =>
    encodeURIComponent(localStorage.getItem("session_cookie") || "");

export const getFrontPageData = async () => {
    const response = await fetch(`${WP_API}/pages?page=2`);
    const data = await response.json();

    return data;
};

type CartBody = {
    product_id: number;
    quantity: number;
    slug: string;
};

export const createCart = async (body: CartBody) => {
    let updateCartId = $updateCartId.get() + 1;
    $updateCartId.set(updateCartId);
    log('add_to_cart_start', getSessionCookieAsURI(), body);
    const response = await fetch(
        `${PRODUCT_API}/add_cart?session_cookie=${getSessionCookieAsURI()}`,
        {
            method: "POST",
            headers: { "Content-Type": "application/json", "Sec-Fetch-Mode": 'cors', "Sec-Fetch-Site": 'cross-site' },
            body: JSON.stringify(body),
        }
    );
    const data = await response.json();
    log('add_to_cart', getSessionCookieAsURI(), data);
    if (data?.session_cookie)
        localStorage.setItem("session_cookie", data?.session_cookie);

    updateCartStore(data, updateCartId);
    $productAddToCart.set(false);
    return data;
};

type UpdateCardBody = {
    cart: Record<string, number>;
};

export const updateCart = async (body: UpdateCardBody) => {
    let updateCartId = $updateCartId.get() + 1;
    $updateCartId.set(updateCartId);
    log('update_cart_start', getSessionCookieAsURI(), body);
    const response = await fetch(
        `${PRODUCT_API}/update_cart?session_cookie=${getSessionCookieAsURI()}`,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );
    const data = await response.json();
    log('update_cart', getSessionCookieAsURI(), data);
    updateCartStore(data, updateCartId);
    return data;
};

export const getShortLinkCart = async (body) => {
    let updateCartId = $updateCartId.get() + 1;
    $updateCartId.set(updateCartId);

    const response = await fetch(
        `${PRODUCT_API}/shortlink_cart`,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );

    const data = await response.json();

    if (data?.session_cookie)
        localStorage.setItem("session_cookie", data?.session_cookie);

    updateCartStore(data, updateCartId);
    return data;
};

export const getCart = async (is_first = true) => {
    try {
        const response = await fetch(
            `${PRODUCT_API}/get_cart?session_cookie=${getSessionCookieAsURI()}`,
            {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.log('getCart error');
        console.log(error);
        if (is_first) {
            return getCart(false);
        }
    }

    return null;
};

// prettier-ignore
export const onConfirmExpressPayment = async (
    body: Record<string, unknown>
) => {
    const response = await fetch(
        `${PRODUCT_API}/stripe-source?session_cookie=${getSessionCookieAsURI()}`,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );
    const data = await response.json();

    return data;
};

export const completePurchase = async (
    body: Record<string, unknown>
): Promise<any> => {
    const response = await fetch(
        `${PRODUCT_API}/checkout?session_cookie=${getSessionCookieAsURI()}`,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );
    const data = await response.json();

    if (data.session_cookie) {
        let updateCartId = $updateCartId.get() + 1;
        $updateCartId.set(updateCartId);
        updateCartStore(data, updateCartId);
        return {
            messages: data.messages
        }
    }



    return data;
};

export const expressPurchaseError = async (
    order_id: number,
    key: string
): Promise<any> => {
    if (order_id) {
        fetch(
            `${PRODUCT_API}/orders/${order_id}/failed?session_cookie=${getSessionCookieAsURI()}`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ key }),
            }
        );
    }
};

export const sendEvent = async (
    event: string,
    body: Record<string, unknown>
): Promise<any> => {
    console.log(`Event. ${event}`);
    const response = await fetch(
        `${PRODUCT_API}/ajax/${event}?session_cookie=${getSessionCookieAsURI()}`,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return await response.json();
    }

    return await response.text();
};

// for testing purposes
export const getAddressFromIP = async () => {
    try {
        const response = await fetch(`https://ipapi.co/json/`);
        const data = await response.json();

        const address = {
            country: data.country,
            postal_code: data.postal_code || "",
            state: "",
            city: data.city || "",
            line1: "",
            line2: "",
        };

        return address;
    } catch (error) {
        console.log(
            "Could not retrieve user's address information, using default address"
        );

        const defaultAddress = {
            country: "US",
            postal_code: "",
            state: "",
            city: "",
            line1: "",
            line2: "",
        };

        return defaultAddress;
    }
};

export const createOrderPaypal: (
    body: Record<string, unknown>
) => Promise<any> = async (body) => {
    const response = await fetch(
        `${PRODUCT_API}/paypal?session_cookie=${getSessionCookieAsURI()}`,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );
    const data = await response.json();

    return data;
};

type Subpath = "paypal" | "paypal-approve";

export const getNonce = async (subpath: Subpath) => {
    const response = await fetch(`${PRODUCT_API}/${subpath}/nonce`);
    const data = await response.json();

    return data;
};

// prettier-ignore
export const onApprovePaypal = async (body: Record<string, unknown>) => {
    const response = await fetch(
        `${PRODUCT_API}/paypal-approve?session_cookie=${getSessionCookieAsURI()}`,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );
    const data = await response.json();

    return data;
};

export const getOrderSkus = async (orderId: string) => {
    const response = await fetch(
        `${PRODUCT_API}/orders/${orderId}/skus?session_cookie=${getSessionCookieAsURI()}}`
    );
    const data = await response.json();

    return data;
};

export const getOrderDetails = async (key: string, orderId: string) => {
    const response = await fetch(
        `${PRODUCT_API}/orders/${orderId}?session_cookie=${getSessionCookieAsURI()}&key=${encodeURIComponent(key)}`
    );
    const data = await response.json();

    return data;
};

// prettier-ignore
export const validateCoupon = async (coupon: string) => {
    const response = await fetch(
        `${PRODUCT_API}/coupon?session_cookie=${getSessionCookieAsURI()}`,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                coupon,
            }),
        }
    );
    const data = await response.json();

    return data;
};

type SignUpForOffersBody = {
    action: string;
    email: string;
    page: string;
};

// prettier-ignore
export const signUpForOffers = async (body: SignUpForOffersBody) => {
    const response = await fetch(
        `${PRODUCT_API}/email?session_cookie=${getSessionCookieAsURI()}`,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                ...body,
            }),
        }
    );
    const data = await response.json();

    return data;
};

// prettier-ignore
export const removeCoupon = async (coupon: string) => {
    const response = await fetch(
        `${PRODUCT_API}/coupon/remove?session_cookie=${getSessionCookieAsURI()}`,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                coupon,
            }),
        }
    );
    const data = await response.json();

    return data;
};

type SubmitReviewBody = {
    product_id: number;
    rating: number;
    review: string;
    reviewer: string;
    reviewer_email: string;
};

// prettier-ignore
export const submitReview = async (body: SubmitReviewBody) => {
    const response = await fetch(
        `${PRODUCT_API}/review?session_cookie=${getSessionCookieAsURI()}`,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                ...body,
            }),
        }
    );
    const data = await response.json();

    return data;
};



// prettier-ignore
export const log = async (source: string, info: string, debug: any | null, is_first = true) => {
    console.log(`Log. ${source}`);
    try {
        fetch(
            `${PRODUCT_API}/log`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    source, info, debug: { ...debug }
                }),
            }
        ).catch((err) => {
            console.log('error log');
            console.error(err)
            if (is_first) {
                return log(source, info, debug, false);
            }
        })
    } catch (error) {
        console.log('error log');
        console.log(error);
        if (is_first) {
            return log(source, info, debug, false);
        }

    }
};
