import { atom, action } from "nanostores";
import { CartData } from "./cartTypes";
import { getCart, removeCoupon, validateCoupon } from "./api";
import { __t } from "./translate";

const persistentAtom = (name: string, initial: undefined | string | boolean | null = undefined, opts = {}) => {
    let encode = opts.encode || JSON.stringify
    let decode = opts.decode || JSON.parse

    let store = atom(initial)
    store.isLocalStorage = true;

    let set = store.set
    store.set = newValue => {
        if (typeof newValue === 'undefined') {
            delete localStorage[name]
        } else {
            localStorage[name] = encode(newValue)
        }
        set(newValue)
    }

    store.restore = () => {
        store.set(localStorage[name] ? decode(localStorage[name]) : initial)
    }

    return store
}

export const $storeLoaded = atom(false);

export const $isOrderSuccessful = persistentAtom('orderSuccessful', undefined);

export const $gdprShown = persistentAtom('gdpr', undefined);
export const $gdprMarketing = persistentAtom('gdpr_marketing', true);
export const $gdprPreferences = persistentAtom('gdpr_preferences', true);
export const $gdprStatistics = persistentAtom('gdpr_statistics', true);
export const $paymentOption = persistentAtom('payment_option', "card");
export const $checkoutNavigation = persistentAtom('checkout_navigation', 'information');
export const $shippingInfo = persistentAtom('shippingInfo', {});
export const $billingInfo = persistentAtom('billingInfo', {});
export const $stocks = persistentAtom('stocks', {});
export const $isLimdiskPopUpClosed = persistentAtom('isLimdiskPopUpClosed', false);

// Example
// export const $test = persistentAtom({LocalStorage name}, {Default value});

// export const $cartStore = atom<CartData | null>(typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('cart') || '') : null);
// export const $cartStore = (() => {
//   try {
//     return atom<CartData | null>(typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('cart') || '') : null);
//   } catch (e) {
//     return atom<CartData | null>(null);
//   }
// })()

export const $cartStore = persistentAtom('cart', null);
// export const $cartStore = atom<CartData | null>(null);

export const $isCheckoutEnabled = atom(true);
export const $isSideCartOpen = atom(false);
export const $isNavBarOpen = atom(false);
export const $isNotFound = atom(false);
export const $isCarouselDisplayed = atom(true);
export const $checkoutErrorMessage = atom<null | string>(null);
export const $limdisc = atom(0);
export const $updateCartId = atom(0);
export const $stateRequired = atom(false);
export const $stateBillingRequired = atom(false);
export const $discountEnded = atom(false);
export const $updatedConsent = atom(false);
export const $productAddToCart = atom(false);
export const $couponHeight = atom(0);
export const $isBundleOutOfStock = atom<Record<string, string>>({});
export const $sliderOpen = atom(false);

export const fastUpdateProductQuantity: (key, quantity) => void = (key, quantity) => {
    const data = $cartStore.get();

    const stripe_cart = typeof data?.stripe_cart === 'object' && Object.keys(data.stripe_cart).length ? data.stripe_cart : new Object();
    const cart = typeof data?.cart === 'object' && Object.keys(data.cart).length ? data.cart : new Object();

    if (key in cart) {
        cart[key].quantity = quantity
    }

    $cartStore.set({ ...data, cart });
}

export const addProductToCartStore: (product) => void = (product) => {
    const data = $cartStore.get();

    const stripe_cart = typeof data?.stripe_cart === 'object' && Object.keys(data.stripe_cart).length ? data.stripe_cart : {
        order_data: {
            total: {
                amount: 0
            },
            displayItems: {
                subtotal: {
                    amount: 0
                },
                shipping: {}
            }
        }
    };
    const cart = typeof data?.cart === 'object' && Object.keys(data.cart).length ? data.cart : new Object();
    let founded = false;
    let key = '';
    for (const item in cart) {
        if (cart[item].product_id == product.product_id && (cart[item].variation_id == product.variation_id || cart[item].variation_id == 0)) {
            founded = true;
            cart[item].quantity += product.quantity
            key = item;
        }
    }
    if (!founded) {
        key = `${product.product_id}_${product.variation_id}`;
        cart[key] = product;
    }

    if (stripe_cart?.order_data) {
        let kf = 1;

        if (data?.applied_coupons?.length && data.applied_coupons_2 && data.applied_coupons_2[data.applied_coupons[0]]?.discount_type === 'percent') {
            kf = (100 - data.applied_coupons_2[data.applied_coupons[0]].coupon_amount) / 100;
        }

        const quantity = cart[key].quantity;
        const priceInCents = Math.round(cart[key].price * 100);

        if (stripe_cart.order_data.total) {
            stripe_cart.order_data.total.amount += priceInCents * quantity * kf;
        }
        if (stripe_cart.order_data.displayItems?.subtotal) {
            stripe_cart.order_data.displayItems.subtotal.amount += priceInCents * quantity;
        }
        if (stripe_cart.order_data.displayItems?.discount) {
            stripe_cart.order_data.displayItems.discount.amount = Math.round(stripe_cart.order_data.displayItems?.subtotal?.amount * (1 - kf));
        }
    }

    $cartStore.set({ ...data, stripe_cart, cart });
};

export const updateCartStore: (cart: CartData, updateCartId: Number) => void = (cart: CartData, updateCartId: Number) => {
    if (updateCartId !== $updateCartId.get()) {
        return;
    }

    $cartStore.set({ ...cart });
    localStorage.setItem('cart', JSON.stringify(cart));

    if (cart?.limdisc?.start && cart?.limdisc?.time) {

        $limdisc.set(cart.limdisc.start + cart.limdisc.time);
    }
    else {
        $limdisc.set(0);
    }
};

export const loadCart = action($cartStore, "loadCart", async () => {
    let updateCartId = $updateCartId.get() + 1;
    $updateCartId.set(updateCartId);

    if (localStorage.getItem("session_cookie")) {
        return getCart().then(res => {
            if (res) {
                updateCartStore(res, updateCartId);
                return res;
            }
        });
    }
    else if (!$productAddToCart.get()) {
        updateCartStore('', updateCartId);
    }
    return null;
});

// prettier-ignore
export const applyCoupon = action($cartStore, 'applyCoupon', async (store, value) => {
    if (value === "") return;
    let updateCartId = $updateCartId.get() + 1;
    $updateCartId.set(updateCartId);

    const res = await validateCoupon(value);

    // handle errors
    if (res?.notice?.length > 0) {
        return res.notice[0].notice;
    }

    updateCartStore(res, updateCartId);
    return __t("Coupon applied successfully!");
})

// prettier-ignore
export const removeCouponFromCart = action($cartStore, 'removeCoupon', async (store, value) => {
    let updateCartId = $updateCartId.get() + 1;
    $updateCartId.set(updateCartId);

    if (value === "") return;

    const data = $cartStore.get();
    const stripe_cart = typeof data?.stripe_cart === 'object' && Object.keys(data.stripe_cart).length ? data.stripe_cart : new Object();
    const cart = typeof data?.cart === 'object' && Object.keys(data.cart).length ? data.cart : new Object();
    if (data) {

        data.applied_coupons = [];
        data.applied_coupons_2 = {};

        if (stripe_cart?.order_data?.displayItems?.discount) {
            delete stripe_cart.order_data.displayItems.discount;
        }
        if (stripe_cart?.order_data?.displayItems?.subtotal?.amount && stripe_cart?.order_data?.total) {
            stripe_cart.order_data.total.amount = stripe_cart.order_data.displayItems.subtotal.amount
        }

        for (const item in cart) {
            if (cart[item].line_subtotal) {
                cart[item].line_total = cart[item].line_subtotal
            }
        }

    }
    $cartStore.set({ ...data });

    const res = await removeCoupon(value);

    // handle errors
    if (res?.applied_coupons?.length > 0) {
        return __t("Unknown error");
    }

    updateCartStore(res, updateCartId);
    return __t("Coupon removed successfully!");
})



export const discountEnded: () => void = () => {
    $discountEnded.set(true);
    return
};
